body {
  --font-color: #e9ecef;
  --primary: #0679FC;
  --secondary: #214875;
  --bg: #000000;
  --bg-radial: #666;
  --bg-radial-2: #666;
  --accent: #214875;
  --accent-dark: #1C395A;
  --accent-light: #0679FC;
  --input: #093D78;
  --bg-nav: rgb(9, 20, 33);
  --muted: #A0A1B5;
  --top: #00000021;
  --bottom: #000000f0;
  --percentage: #dadada;
  --table-color: #e9ecef;
  --screen-dim: #2d2d2d6e;
  color: var(--font-color);
  --hover-state: #0665D1;
  --pressed-state: #126d9a;
  --card-border-color: #093D78;
  --bg-navy-1: #002F64;
  --bg-navy-2: #0E1D2E;
  --purple: #1C395A;
  --blue-hover: #0665D1;
  --bg-2: #0E1D2E;
  --blue-2:#0078FF;
}

body.light-theme {
  --font-color: #000000;
  --primary: #2c82ff;
  --secondary: #A0B0BE;
  --bg: #F2F3F3;
  --bg-radial: #F2F3F3;
  --bg-radial-2: #F2F3F3;
  --accent: #A0A1B5;
  --accent-dark: #E0E0E0;
  --accent-light: #0679FC;
  --input: #EAEAEA;
  --bg-nav: rgb(255, 255, 255);
  --muted: #747596;
  --top: #fff0;
  --bottom: #ffffff8a;
  --percentage: #8c8c8c;
  --table-color: #616264;
  --screen-dim: #00000080;
  --hover-state: #0679FC;
  --pressed-state: #98c1fe;
  --card-border-color: #A0A1B5;
  --bg-navy-1: #FFFFFF;
  --bg-navy-2: #FFFFFF;
  --purple: #E0E0E0;
  --blue-hover: #0679FC;
  --bg-2: #EBEBEB;
  --blue-2:#EBEBEB;
}

.bg-primary {
  background-color: var(--primary) !important;
}

.bg-secondary {
  background-color: var(--accent-dark) !important;
}

.bg-radial {
  background: radial-gradient(closest-side at 50% 50%, var(--blue-2) -30%, var(--bg-2) 100%);
}

.bg-accent {
  background-color: var(--accent);
}

.bg-linear {
  background: transparent linear-gradient(180deg, var(--accent-light) 0%, var(--bg) 100%) !important;
}

.bg-dark-linear {
  background: transparent linear-gradient(180deg, var(--accent-dark) 0%, var(--bg) 100%) !important;
}

.bg-top-bar {
  background: linear-gradient(90deg, var(--bg) 0%, var(--primary) 100%) !important;
}

.btn-primary, .btn-primary:focus {
  color: #fff !important;
  background-color: var(--primary);
  border-color: var(--primary);
}

.btn-primary:disabled {
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
}

.btn-secondary {
  background-color: var(--secondary);
  border-color: var(--secondary);
}
.btn-secondary:hover {
  background-color: var(--accent-dark);
  border-color: var(--accent-dark);
}

.btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: var(--bg);
  border-color: var(--primary);
}

.btn-outline-primary:not(:disabled):not(.disabled).active, .btn-outline-primary:not(:disabled):not(.disabled):active, .show > .btn-outline-primary.dropdown-toggle {
  background-color: var(--bg);
}

.btn-primary:hover, .btn-primary:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: var(--blue-hover);
  border-color: var(--blue-hover);
}

.btn-outline-primary:not(:disabled):not(.disabled).active:focus, .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem var(--accent);
}

.btn-outline-primary:not(:disabled):not(.disabled).active:focus, .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem var(--accent);
}

.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem var(--accent);
}

.btn-outline-primary:focus, .btn-primary:focus {
  box-shadow: 0 0 0 0.2rem var(--accent);
}

.btn-close {
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat !important;
}

.light-theme .btn-close {
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat !important;
}

.list-group-item-action:focus, .list-group-item-action:hover {
  color: var(--bg);
}

a {
  color: var(--primary);
}

.btn-success {
  color: #fff;
}

.btn-bubble:focus {
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
}

.btn-bubble:focus:before {
  background-image: radial-gradient(circle, var(--primary) 20%, transparent 21%), radial-gradient(circle, var(--primary) 20%, transparent 21%), radial-gradient(circle, var(--primary) 20%, transparent 21%), radial-gradient(circle, var(--primary) 20%, transparent 21%), radial-gradient(circle, var(--primary) 20%, transparent 21%), radial-gradient(circle, var(--primary) 20%, transparent 21%);
  /* bottom */
}

.dropdown-item.active, .dropdown-item:active {
  background-color: var(--primary);
}

.list-group-item.active {
  background-color: var(--accent);
  border-color: var(--accent);
}

.btn:focus {
  box-shadow: none;
}

.btn-link {
  color: var(--primary);
}

.btn-outline-primary {
  color: var(--primary);
  border-color: var(--primary);
}

.btn-outline-secondary {
  border-color: var(--secondary);
}
.btn-outline-secondary:hover, .btn-outline-secondary:active, .btn-outline-secondary:focus {
  border-color: var(--secondary);
  background-color: var(--secondary);
}

.btn-outline-primary:hover,
.btn-outline-primary:focus {
  color: #fff;
  background-color: var(--primary);
  border-color: var(--primary);
}

.btn-outline-accent {
  border: 2px solid #13530E;
  color: #ffffff;
}

.btn-outline-accent:hover {
  color: #ffffff;
  background-color: #13530E;
  border: 2px solid #13530E;
}

.border-accent {
  border-color: var(--accent) !important;
}

.border-secondary {
  border-color: var(--accent-dark) !important;
}

.border-primary {
  border-color: var(--primary) !important;
}

.text-primary {
  color: var(--primary) !important;
}

.text-secondary {
  color: var(--accent-dark) !important;
}

.text-muted {
  color: var(--muted) !important;
}

.text-white {
  color: #FFF !important;
}

.light-theme .text-white {
  color: #000000 !important;
}

.modal-content {
  background-color: var(--bg);
}

.modal-header, .modal-body, .modal-footer {
  border-color: var(--accent-dark);
}

.card {
  border: none;
  border-bottom: solid 0.5rem var(--card-border-color);
  background: linear-gradient(242deg, var(--bg-navy-1) 0%, var(--bg-navy-2) 100%);
  border-radius: 0.5rem;
}
.card .card-header {
  border-bottom: 2px solid var(--accent-dark);
  background-color: transparent !important;
}
.card .card-footer {
  border-top: 1px solid var(--accent-dark);
  background-color: transparent;
}

.top-bar {
  border-bottom-color: var(--purple);
}

.nav-tabs {
  border-bottom: 3px solid var(--accent-dark);
}
.nav-tabs .nav-link {
  color: var(--font-color) !important;
}
.nav-tabs .nav-link:hover {
  color: var(--primary) !important;
}
.nav-tabs .active {
  color: var(--primary) !important;
  border-bottom: 2px solid var(--primary) !important;
}

.wcal .wcal-day-hour.disabled {
  background-color: var(--bg);
}

.wcal .wcal-day.disabled {
  color: var(--bg);
}

.wcal-grid-scroll-wrapper {
  scrollbar-color: var(--accent) var(--accent-dark);
}

.loading-board {
  background-color: var(--accent-dark);
  opacity: 0.6;
}

.img-bg {
  background-color: var(--secondary);
}

.active-teacher {
  background-color: var(--accent-dark);
}

.teacher-list {
  border-top: 1px solid var(--accent-dark);
}

.nav-container .dropdown-divider {
  border-color: var(--accent-dark);
}

.dropdown-menu {
  background-color: var(--bg-nav);
  box-shadow: 0px 0px 10px #0f7ef886;
}
.dropdown-menu::after {
  border-bottom-color: var(--bg-nav);
}
.dropdown-menu .dropdown-item:hover {
  background-color: var(--accent-dark);
  color: var(--font-color);
}

.lessons-list li:nth-child(2n) {
  background-color: var(--accent-dark);
}

.form-floating label, .dropdown-menu, .btn-primary, .btn-secondary, .btn-outline-primary, .btn-outline-secondary {
  font-family: "Inter", sans-serif !important;
}

.form-control, .form-select {
  color: var(--font-color);
  background-color: var(--input) !important;
  border-color: var(--input) !important;
}
.form-control:focus, .form-select:focus {
  color: var(--font-color);
}

.form-file {
  background-color: var(--input);
}

.photo-square {
  border-color: var(--primary);
}

.form-check-input[type=radio]:checked {
  background-color: var(--primary) !important;
}

.form-check-input {
  background-color: transparent;
  border: 2px solid var(--primary);
}

.form-check-input:checked {
  background-color: var(--primary);
  border-color: var(--primary);
}

.photo-circle {
  border-color: var(--primary);
}

.table {
  color: var(--table-color) !important;
}

thead > tr > th {
  background-color: var(--accent-dark) !important;
}

tbody > tr:nth-child(even) > td {
  background-color: #78b6fd2e;
}

.teacher-profile-photo::after {
  background: linear-gradient(180deg, var(--top) 0%, var(--bottom) 85%) 0% 0% no-repeat padding-box;
}

.percentage {
  fill: var(--percentage);
}

@media screen and (min-width: 768px) {
  .top-bar .top-bar-footer {
    border-top: 2px solid var(--accent-dark);
  }
}
.custom .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255,0.7)' stroke-width='3' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.offcanvas:not(.offcanvas-cart) {
  background-color: var(--accent);
  padding: 2.5rem 1.5rem;
}
.offcanvas:not(.offcanvas-cart) .offcanvas-body {
  padding: 0;
}

.nav-item .nav-link {
  color: var(--font-color);
}
.nav-item .nav-link:hover {
  color: var(--primary);
}

.nav-item.active {
  background-color: var(--accent-dark);
}

.tutorial-popover {
  background-color: var(--accent);
  border-color: var(--accent);
}