/* stage list summary */
.stage-list-container {
  overflow-x: auto;
}

.stage-list {
  width: 100%;
  position: relative;
  overflow: hidden;
  height: 96px;
}

.single-chart {
  cursor: pointer;
  margin: 0px 0px;
  position: absolute;
  width: 96px;
  transform: scale(0);
  transition: 0.6s;
}

.prev .single-chart {
  transform: translateX(-50%) scale(0.5) translateX(-170%);
  left: 50%;
}

.left .single-chart {
  transform: translateX(-50%) scale(0) translateX(-170%);
  left: 50%;
}

.selected .single-chart {
  transform: scale(1) translateX(-50%);
  left: 50%;
}

.next .single-chart {
  transform: translateX(-50%) scale(0.5) translateX(170%);
  left: 50%;
}

.right .single-chart {
  transform: translateX(-50%) scale(0) translateX(170%);
  left: 50%;
}

.circle-bg {
  fill: none;
  stroke: #80808029;
}

.circle {
  fill: none;
  stroke-linecap: round;
  -webkit-animation: 1s ease-out forwards progress;
  animation: 1s ease-out forwards progress;
}

.circle-bg-1 .circle {
  stroke: #E21A22;
}

.circle-bg-2 .circle {
  stroke: #9B1237;
}

.circle-bg-3 .circle {
  stroke: #FAA719;
}

.circle-bg-4 .circle {
  stroke: #F15C27;
}

.circle-bg-5 .circle {
  stroke: #B2C636;
}

.circle-bg-6 .circle {
  stroke: #37853F;
}

.circle-bg-7 .circle {
  stroke: #65A3D3;
}

.circle-bg-8 .circle {
  stroke: #325995;
}

.circle-bg-9 .circle {
  stroke: #CC93C1;
}

.circle-bg-10 .circle {
  stroke: #92518A;
}

.circle-bg-11 .circle {
  stroke: #C0BC9D;
}

.circle-bg-12 .circle {
  stroke: #69626F;
}

.percentage {
  font-family: sans-serif;
  font-size: 0.6rem;
  text-anchor: middle;
}

.second-line {
  fill: #666;
  font-family: sans-serif;
  font-size: 0.3rem;
  text-anchor: middle;
}

/* exercise blocks */
.exercise-entry-block {
  width: 48px;
  height: 48px;
  text-align: center;
  vertical-align: middle;
  display: inline-block;
  line-height: 48px;
  margin: 2px;
  color: #fff;
  border-radius: 4px;
}

.text-light hr {
  border-top: 1px solid #ffffff1a;
  margin-left: 10%;
  margin-right: 10%;
}

.ready {
  background: #0679FC;
}
.ready:hover {
  background: #0765d1;
}

.perfect {
  background: #27A51C;
}
.perfect:hover {
  background: #218518;
}

.good {
  background: #70C508;
}
.good:hover {
  background: #62ac09;
}

.enough {
  background: #F09800;
}
.enough:hover {
  background: #ca8000;
}

.bad {
  background: #FF4814;
}
.bad:hover {
  background: #cf3c14;
}

.locked {
  background: #343E5B;
}
.locked:hover {
  background: #343E5B;
}