 .shadow-pulse {
  -webkit-animation: shadow-pulse 1.2s ease 2;
          animation: shadow-pulse 1.2s ease 2;
}

@-webkit-keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes appear-slide {
  0% {
    clip-path: polygon(100% 0, 100% 0, 0 0, 0 0);
    translate: 0 -30px;
    opacity: .5;
  }
  100% {
    clip-path: polygon(100% 100%, 100% 0, 0 0, 0 100%);
    translate: 0 0px;
    opacity: 1;
  }
}
@keyframes appear-slide {
  0% {
    clip-path: polygon(100% 0, 100% 0, 0 0, 0 0);
    translate: 0 -30px;
    opacity: .5;
  }
  100% {
    clip-path: polygon(100% 100%, 100% 0, 0 0, 0 100%);
    translate: 0 0px;
    opacity: 1;
  }
}

@-webkit-keyframes shadow-pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0px #27a51c8a;
    -moz-box-shadow: 0 0 0 0px #27a51c8a;
    box-shadow: 0 0 0 0px #27a51c8a;
  }
  100% {
    -webkit-box-shadow: 0 0 0 15px #27a51c00;
    -moz-box-shadow: 0 0 0 15px #27a51c00;
    box-shadow: 0 0 0 15px #27a51c00;
  }
}
@keyframes shadow-pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0px #27a51c8a;
    -moz-box-shadow: 0 0 0 0px #27a51c8a;
    box-shadow: 0 0 0 0px #27a51c8a;
  }
  100% {
    -webkit-box-shadow: 0 0 0 15px #27a51c00;
    -moz-box-shadow: 0 0 0 15px #27a51c00;
    box-shadow: 0 0 0 15px #27a51c00;
  }
}
