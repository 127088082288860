@charset "UTF-8";
/* You can add global styles to this file, and also import other style files */
/* avoid links uncoloring by bootstrap

a:not([href]):not([tabindex]) {
  color: #007bff;
  text-decoration: none;


}
a:not([href]):not([tabindex]):hover {
  color: #0056b3;
      text-decoration: underline;
}

a:not([href]):not([tabindex]):focus {
  color: #0056b3;
      text-decoration: underline;
}
*/
/* brand logo size adjust */
@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
body {
  font-family: "DM Sans", sans-serif;
}

a {
  cursor: pointer;
}

a.navbar-brand {
  padding: 0px;
}

a.navbar-brand img {
  max-height: 40px;
}

.form-group.search-group {
  position: relative;
}

.textarea-height {
  height: 8rem !important;
}

ul.input-results {
  list-style: none;
  -webkit-padding-start: 0;
  padding-inline-start: 0;
  box-shadow: #ccc 0px 3px 5px -2px;
  margin: 0px 1px 0px 1px;
  z-index: 10;
  position: absolute;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  background-color: #fff;
}

ul.input-results li {
  padding: 10px 0px 10px 0px;
}

div.profile-photo {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

div.profile-photo:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

div.screen-dim {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1100;
  background-color: var(--screen-dim);
}

.tutorial-popover.below {
  top: 2.5rem;
}

.tutorial-popover.above {
  bottom: 5rem;
}

.tutorial-popover.above-card {
  bottom: calc(100% + .5rem);
}

.tutorial-popover.below-card {
  top: calc(100% + .5rem);
}

.tutorial-popover.below.top-100 {
  top: 100px;
}

.tutorial-popover.right {
  right: 0;
}

.tutorial-popover.left {
  left: 0;
}

.tutorial-popover.left.right {
  max-width: none;
}

.tutorial-popover.wide {
  max-width: 400px;
}

.tutorial-popover.xwide {
  max-width: 800px;
}

.tutorial-popover {
  position: absolute;
  max-width: 200px;
  padding: 1rem 2rem;
  font-family: sans-serif;
  border-radius: 0.5rem;
  display: none;
  z-index: 1300;
  max-width: 55rem;
}

.tutorial-popover.popover-arrow-top.popover-arrow-left:before {
  left: 10px;
}

.tutorial-popover.popover-arrow-top.popover-arrow-right:before,
.tutorial-popover.popover-arrow-bottom.popover-arrow-right:before {
  right: 10px;
}

.tutorial-popover.popover-arrow-top:before {
  content: "";
  position: absolute;
  border: 15px solid transparent;
  border-bottom-color: inherit;
  top: -1.5rem;
}

.tutorial-popover.popover-arrow-bottom:before {
  content: "";
  position: absolute;
  border: 15px solid transparent;
  border-top-color: inherit;
  bottom: -1.5rem;
}

.tutorial {
  position: relative;
  z-index: 1200;
}

.tutorial.global {
  z-index: 1300;
}

.tutorial-popover.global {
  position: fixed;
  margin: auto;
  min-width: 300px;
  left: 0;
  right: 0;
  top: 4rem;
}

.top-100 {
  top: 100px;
}

.tutorial.card-header {
  background-color: #fff;
}

.tutorial .tutorial-popover {
  display: block;
}

.lesson-table-tooltip {
  display: inline-block;
  margin-left: 0.5em;
  position: relative;
}

.lesson-table-tooltip .lesson-table-tooltip-content {
  display: none;
  position: absolute;
  right: 0;
  text-align: left;
  background-color: #fff;
  max-width: 11rem;
  width: max-content;
  font-size: 0.8rem;
  color: rgba(0, 0, 0, 0.815);
  z-index: 1000;
  top: 2rem;
}

.lesson-table-tooltip:hover .lesson-table-tooltip-content {
  display: block;
}

.lesson-table-tooltip button:focus ~ .lesson-table-tooltip-content {
  display: block;
}

.text-del {
  text-decoration: line-through;
}

.course-select {
  border: 0.5px solid #0d320a;
  min-height: 8rem;
}

.course-select:hover {
  background-color: #27a51c;
  border-color: #27a51c;
}

/* products */
.lessonBox .header {
  border-top-left-radius: 13px;
  border-top-right-radius: 13px;
  background-color: #066bc1;
  color: #fff;
  padding: 10px 12px 0px 12px;
}

.lessonBox .buy-desc {
  display: block;
  text-align: center;
  font-size: 0.7rem;
  line-height: 2.1;
  color: #797979;
}

.lessonBox.starter .header {
  background-color: #542865;
}

.lessonBox.starter {
  box-shadow: #171717b0 5px 10px 15px 0px;
  background-image: linear-gradient(to bottom left, #e8e8e8, #ffffff);
}

.lessonBox.starter .buy-desc {
  color: #5428658a;
}

.lessonBox.starter .btn-outline-primary {
  color: #542865;
  border-color: #542865;
}

.lessonBox.starter .btn-outline-primary:hover {
  color: #fff;
  background-color: #542865;
}

.light .lessonBox .header {
  background-color: #06c173;
}

.card.payg .card-header {
  border-left-color: #07bbd8;
  border-left-style: solid;
  border-left-width: 16px;
}

.card.light .card-header {
  border-left-color: #07c173;
  border-left-style: solid;
  border-left-width: 16px;
}

.card.medium .card-header {
  border-left-color: #ef9817;
  border-left-style: solid;
  border-left-width: 16px;
}

.card.intensive .card-header {
  border-left-color: #dc3605;
  border-left-style: solid;
  border-left-width: 16px;
}

.breadcrumb {
  padding-top: 0.5rem;
  font-size: 0.8rem;
  background-color: transparent;
}
.breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  content: var(--bs-breadcrumb-divider, ">") !important;
  color: #A0A1B5 !important;
}
.breadcrumb a {
  color: #A0A1B5 !important;
  text-decoration: none;
  cursor: pointer;
}
.breadcrumb .active {
  color: #e9ecef;
}

.card {
  padding: 0 2rem;
}
.card .card-header, .card .card-body, .card .card-footer {
  padding: 2rem 0;
}

tr > th, tr > td {
  padding-top: 0.8rem !important;
  padding-bottom: 0.8rem !important;
  font-size: 0.875rem;
}
tr > th:first-child, tr > td:first-child {
  border-bottom-left-radius: 0.5rem !important;
  border-top-left-radius: 0.5rem !important;
  padding-left: 1.7rem;
}
tr > th:last-child, tr > td:last-child {
  border-bottom-right-radius: 0.5rem !important;
  border-top-right-radius: 0.5rem !important;
  padding-right: 1.7rem;
}
tr > td {
  cursor: pointer;
}
tr:hover > td {
  opacity: 0.7;
}

thead > tr > th {
  vertical-align: middle !important;
}

.table td, .table th, .table thead th {
  border: none !important;
}

.medium .lessonBox .header {
  background-color: #ef9817;
}

.intensive .lessonBox .header {
  background-color: #dc3605;
}

.payg .lessonBox .header {
  background-color: #07bbd8;
}

.lessonBox {
  width: 180px;
  margin-bottom: 15px;
  margin-top: 10px;
  margin-right: 8px;
  margin-left: 8px;
  height: 220px;
  float: left;
  position: relative;
  /* border-radius: 19px; */
  /* border: solid #fdfdfd 1px; */
  border-radius: 16px;
  /* background-color: #fafffa; */
  box-shadow: #adadad 5px 7px 15px -2px;
  background-image: linear-gradient(to bottom left, #e8e8e8, #ffffff);
}

.lessonBox .name {
  display: block;
  text-align: center;
  font-size: 1rem;
  line-height: 22px;
  min-height: 53px;
  font-weight: 100;
}

.lessonBox .currency {
  color: #000;
  font-size: 0.8rem;
}

.lessonBox .description {
  display: block;
  font-size: 0.8rem;
  text-align: center;
  min-height: 14px;
  color: #000;
  font-weight: 300;
}

.lessonBox .price-box {
  text-align: center;
  bottom: 65px;
  line-height: 1.3;
  padding: 20px 30px;
  position: absolute;
  width: 100%;
}

.lessonBox .price {
  font-weight: 500;
  font-size: 1.8rem;
  color: #5b5e63;
}

.lessonBox .buy-section {
  /* margin-top: 15px; */
  bottom: 13px;
  position: absolute;
  width: 100%;
}

.chart-container {
  width: max-content;
  margin-left: auto;
  margin-right: auto;
}

.form-floating .required::after {
  content: " *";
  color: red;
}

.book-product {
  width: 120px;
}

/* lesson status */
.lessons-list li {
  border-radius: 0.5rem;
}

.lesson-status {
  width: fit-content;
}

.lesson-status-legent-wrapper {
  margin-right: -4px;
  overflow: hidden;
  margin-bottom: 10px;
  margin-top: -15px;
}

.lesson-status {
  color: #fff;
  font-weight: initial;
  border-radius: 0.25rem;
  padding: 0.25rem 0.5rem;
}

.lesson-status.booked {
  background-color: #95d566;
}

.lesson-status.due {
  background-color: #529f19;
}

.lesson-status.in-progress {
  background-color: #84c3ff;
}

.lesson-status.cancel {
  background-color: #dadada;
}

.lesson-status.complete {
  background-color: #076fd0;
}

.lesson-status.student-invalidate {
  background-color: #9e9e9e;
}

.lesson-status.teacher-invalidate {
  background-color: #484848;
}

table .lesson-revision > td, .card.lesson-form.lesson-revision .card-header {
  background-color: #F15C27;
  color: #fff;
}

table .lesson-student-no-show > td {
  background-color: #C0C0C0;
}

table .lesson-tutor-no-show > td {
  background-color: #808080;
  color: #fff;
}

.course-accordion {
  cursor: pointer;
}

/* Chrome, Safari, Edge, Opera */
.input-arrows-none::-webkit-outer-spin-button,
.input-arrows-none::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.input-arrows-none[type=number] {
  -moz-appearance: textfield;
}

.bg-smoke {
  background-image: url(/assets/img/smoke_full.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.bg-cover {
  background-size: cover !important;
}

a.text-white {
  color: #fff;
  text-decoration: underline;
}

ul.dot-bullet li:before {
  content: "•";
  margin-left: 6px;
  margin-right: 6px;
}

.text-large {
  font-size: 1.5rem;
}

.text-xlarge {
  font-size: 2rem;
}

.text-thin {
  font-weight: 200;
}

.text-xsmall {
  font-size: 0.75rem;
}

.pe-none {
  pointer-events: none;
}

.bg-party {
  background-image: url(/assets/img/itsapartyyo.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.tutorial-highlight-animation {
  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  z-index: 1;
}

.tutorial-highlight-animation:after {
  content: "";
  border-radius: 5px;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  opacity: 0;
  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.tutorial-highlight-animation.on {
  -webkit-transform: scale(1.25, 1.25);
  transform: scale(1.25, 1.25);
  z-index: 2;
}

.tutorial-highlight-animation.on:after {
  opacity: 1;
}

.tutorial-intro {
  margin-top: 15px;
  margin-bottom: 15px;
  color: #26a745;
  font-size: 1.5rem;
  font-weight: 300;
}

.top-bar {
  border-bottom-width: 4px;
  border-bottom-style: solid;
  background: var(--bg-nav);
  padding: 2rem 1.5rem 1rem 1.5rem;
}
.top-bar .top-bar-content {
  display: flex;
  align-items: center;
  padding: 0.5rem 0rem;
  min-height: 3rem;
}

.f-14 {
  font-size: 0.875rem;
}

.f-12 {
  font-size: 0.75rem;
}

.f-64 {
  font-size: 4rem;
}

.w-fit-content {
  width: fit-content;
}

.img-thumbnail {
  padding: 0 !important;
  border-width: 0 !important;
}

.form-floating label {
  color: #A0A1B5;
  font-size: 0.75rem !important;
  padding: 1.2rem 0.75rem;
}

.form-control, .form-select {
  font-size: 0.75rem !important;
  font-family: "Inter", sans-serif;
}

.form-select {
  padding-left: 0.75rem;
  background-image: url("./assets/img/expand-more.svg") !important;
}
.form-select ~ label {
  opacity: 1 !important;
  transform: unset !important;
}
.form-select.select-not-empty ~ label {
  opacity: 0.65 !important;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem) !important;
}

.form-check-input[type=radio] {
  background-color: transparent !important;
  border: 2px solid #A0A1B5;
  border-radius: 8px;
}

.form-check-input[type=radio]:checked {
  border: none;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
}

.list-group-item-action {
  color: #495057;
}
.list-group-item-action:focus, .list-group-item-action:hover {
  z-index: 1;
  text-decoration: none;
  background-color: #f8f9fa;
}

.highlight {
  background-color: #e6ebf1;
  border-radius: 3px;
}

a.text-white:hover {
  color: #a7b2ff;
}

.top-bar-content {
  display: flex;
  align-items: center;
  padding: 0.5rem 0rem;
  min-height: 3rem;
}

.tutorial .popover-arrow {
  flex: 0 0 80px !important;
  max-width: 80px !important;
}

.img-thumbnail {
  padding: 0 !important;
  border-width: 0 !important;
}

.placeholder {
  min-width: 4rem;
}

.offcanvas {
  z-index: 1400;
}

.nav-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.nav-container .dropdown-profile {
  left: -4.4rem !important;
}
.nav-container .dropdown-profile::after {
  top: -1rem;
  left: 50%;
  margin-left: -20px;
  margin-bottom: 120px;
}
.nav-container .dropdown-notifications {
  left: -7.4rem !important;
}
.nav-container .dropdown-notifications::after {
  top: -1rem;
  left: 83%;
  margin-left: -20px;
  margin-bottom: 120px;
}
.nav-container .dropdown-divider {
  height: 2px;
}
.nav-container .btn-circle {
  width: 3rem;
  height: 3rem;
  padding: 0;
  border-radius: 50%;
  font-size: 1rem;
}

.dropdown-menu {
  padding: 0.5rem;
  border-radius: 0.5rem;
  width: 12rem;
  top: 3.2rem;
  z-index: 1400;
}
.dropdown-menu::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border: 20px solid transparent;
  border-top: 0;
}
.dropdown-menu .dropdown-item {
  padding: 0.5rem;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  height: 3rem;
  text-transform: capitalize;
  font-size: 0.8rem;
}
.dropdown-menu .dropdown-item.active, .dropdown-menu .dropdown-item:active {
  color: #fff;
  text-decoration: none;
}
.dropdown-menu .dropdown-item i {
  font-size: unset;
}

.dropdown-lang {
  left: -5.15rem !important;
  top: 1rem !important;
}
.dropdown-lang::after {
  top: -1rem;
  left: 50%;
  margin-left: -20px;
  margin-bottom: 120px;
}

.top-bar {
  background: var(--bg-nav);
  padding: 2rem 0 0 0;
  position: static;
  width: 100%;
}
.top-bar .top-bar-content {
  text-align: left;
  padding: 0 2rem 0 3rem;
}
.top-bar .top-bar-footer {
  padding: 0.75rem 3rem 0rem 3rem;
}
.top-bar .nav-container .btn-circle {
  font-size: 1.4rem;
}

.btn-link:focus {
  text-decoration: none;
}

.btn-bubble {
  position: relative;
  z-index: 10;
  width: fit-content;
  padding-inline: 1rem;
}

.btn-bubble:after {
  content: "";
  position: absolute;
  left: -0.2rem;
  bottom: -0.1rem;
  border: 0.2rem solid transparent;
  border-color: inherit;
  border-radius: 50%;
  height: 0.4rem;
}

.btn-bubble:focus:before {
  position: absolute;
  content: "";
  display: block;
  z-index: -1000;
  transition: all ease-in-out 0.5s;
  background-repeat: no-repeat;
}

.btn-bubble:focus:before {
  display: none;
  top: -75%;
  width: 160%;
  height: 280%;
  left: -30%;
  display: block;
  animation: bubbles ease-in-out 1.1s forwards 1;
  background-size: 30% 30%, 60% 60%, 25% 25%, 40% 40%, 45% 45%, 70% 70%;
  /* bottom */
  background-position: 10% 20%, -5% 25%, 88% 40%, 90% 26%, 94% 27%, 90% 55%;
  background-position: -10% -10%, -25% -10%, 93% 34%, 106% -18%, 114% 8%, 100% 120%;
}

.highlight {
  background-color: #e6ebf1;
  border-radius: 3px;
}

a.text-white:hover {
  color: #a7b2ff;
}

.custom.navbar-toggler {
  border-color: rgba(255, 255, 255, 0.7);
}

.nav-tabs .nav-link, .nav-tabs .active {
  border: none;
  background-color: transparent !important;
  padding: 1.5rem 0.25rem;
}

.navbar-nav .nav-item {
  display: flex;
  align-items: center;
  border-radius: 0.25rem 0 0 0.25rem;
  padding-left: 1rem;
  margin: 0.4rem 0;
  height: 3rem;
  width: 100%;
  cursor: pointer;
}
.navbar-nav .nav-item .nav-link {
  font-size: 0.8rem;
  padding-left: 0;
  text-transform: capitalize;
}

.opacity-75 {
  opacity: 0.75;
}

.course {
  background: linear-gradient(180deg, #434343 0%, #000000 100%) 0% 0% no-repeat padding-box;
  border-radius: 0.5rem;
  padding: 2rem;
}

.light-theme .course {
  background: linear-gradient(180deg, #c3b0b0 0%, #635c5c 100%) 0% 0% no-repeat padding-box;
}

.course > * {
  position: relative;
}

.course-en, .course-sp, .course-be {
  position: relative;
  border-radius: 0.5rem;
}

.course-en::before, .course-sp::before, .course-be::before {
  content: "";
  position: absolute;
  border-radius: 0.5rem;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

.course-en::before {
  background-image: url("https://assets.callan.app/callanonline/uk-flag.png");
  background-position-y: center;
}

.course-sp::before {
  background-image: url("https://assets.callan.app/callanonline/spain-flag.png");
  background-position-y: 30%;
}

.course-be::before {
  background-image: url("https://assets.callan.app/callanonline/be-bg.png");
  background-position-y: center;
}

.modal-header {
  align-items: flex-start !important;
}

.teacher-profile-photo {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 0.5rem;
  background-color: #FFF;
}

.teacher-profile-photo::after {
  content: "";
  display: block;
  padding-bottom: 100%;
  border-radius: inherit;
}

.initials-circle-box {
  display: flex;
  width: 3.5rem;
  min-width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  background-color: var(--accent-dark);
  color: var(--primary);
}

.animation-main-menu {
  animation: slideRight 0.2s ease-in-out;
}

.animation-profile-menu {
  animation: slideLeft 0.2s ease-in-out;
}

.screen-bg {
  top: 0;
  left: 0;
  object-fit: cover;
  z-index: 100;
  height: 100vh;
}

.step-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 2.25rem;
  height: 2.25rem;
  font-size: 0.9rem;
  font-family: "Sanchez", Courier, monospace;
}

.step-circle-primary {
  color: #fff;
  border: 2px solid #27a51c;
  background-color: #27a51c;
}

.step-circle-outline-primary {
  color: #27a51c;
  border: 2px solid #27a51c;
}

.step-circle-outline-secondary {
  color: #a0a1b5;
  border: 2px solid #A0A1B5;
}

.horizontal-line {
  margin: auto 0;
  width: calc((100% - 3 * 2.25rem)/2);
  height: 2px;
}

.line-half {
  background: linear-gradient(to right, #27a51c 50%, #a0a1b5 50%);
}

.line-complete {
  background: #27a51c;
}

.line-incomplete {
  background: #a0a1b5;
}

.dark-theme .modal-content .form-select,
.dark-theme .modal-content .form-control {
  background-color: var(--bg) !important;
}

.modal-content .form-check-input[type=radio] {
  background-color: var(--bg) !important;
  border-color: #27a51c;
}

.modal-content .form-check-input[type=radio]:checked {
  background-color: var(--bg) !important;
}

.light-theme .modal-content .form-check-input[type=radio]:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23000'/%3e%3c/svg%3e") !important;
}

@keyframes slideRight {
  from {
    transform: translateX(-40%);
    opacity: 0.3;
    width: 75%;
  }
  to {
    transform: translateX(0%);
    opacity: 1;
    width: 100%;
  }
}
@keyframes slideLeft {
  from {
    transform: translateX(40%);
    opacity: 0.3;
    width: 75%;
  }
  to {
    transform: translateX(0%);
    opacity: 1;
    width: 100%;
  }
}
@media screen and (min-width: 576px) {
  .nav-tabs .nav-link, .nav-tabs .active {
    padding: 1rem 0.5rem;
  }
  .nav-tabs .nav-link:focus, .nav-tabs .active:focus {
    outline: none !important;
  }
}
@media screen and (min-width: 768px) {
  .workspace {
    margin-left: 12rem;
  }

  .animation-main-menu {
    animation: none;
  }

  .tutorial-popover.global {
    left: 12rem;
  }
}
@media screen and (min-width: 992px) {
  .top-bar .top-bar-content {
    text-align: left;
    min-height: 3rem;
    padding-left: 6rem;
  }
  .top-bar .top-bar-content p {
    padding-left: 3rem;
  }
  .top-bar .top-bar-footer {
    padding-left: 6rem;
  }
}
/* snipcart styling */
.snipcart-modal__container {
  padding-top: 60px !important;
}

button.snipcart-discount-box__button {
  color: white;
  background: #cc2b2b;
}

button.snipcart-discount-box__button:hover {
  background: #a53838;
}

button.snipcart-discount-box__submit {
  color: #e9ecef;
  font-weight: 800;
  /* font-size: 18px; */
  height: 54px;
  background: #cc2b2b;
  padding: 15px;
  margin-left: 15px;
}

button.snipcart-discount-box__submit:hover {
  background: #a53838;
}

.snipcart-form.snipcart-discount-box__form {
  padding-right: 0;
}

@media screen and (min-width: 768px) {
  .border-md-right {
    border-right-width: 1px;
    border-right-style: solid;
  }
}
@media screen and (min-width: 992px) {
  .border-lg-right {
    border-right-width: 1px;
    border-right-style: solid;
  }
}
@-webkit-keyframes bubbles {
  0% {
    background-position: 10% 20%, -5% 25%, 88% 40%, 90% 26%, 94% 27%, 90% 55%;
    opacity: 1;
  }
  50% {
    background-position: 0% 5%, -15% 8%, 91% 37%, 98% 4%, 104% 18%, 95% 88%;
    opacity: 1;
  }
  80% {
    background-position: -6% -4%, -21% 3%, 92% 35%, 103% -9%, 110% 12%, 98% 107%;
    opacity: 0.9;
  }
  100% {
    background-position: -10% -10%, -25% -10%, 93% 34%, 106% -18%, 114% 8%, 100% 120%;
    opacity: 0;
  }
}
@keyframes bubbles {
  0% {
    background-position: 10% 20%, -5% 25%, 88% 40%, 90% 26%, 94% 27%, 90% 55%;
    opacity: 1;
  }
  50% {
    background-position: 0% 5%, -15% 8%, 91% 37%, 98% 4%, 104% 18%, 95% 88%;
    opacity: 1;
  }
  80% {
    background-position: -6% -4%, -21% 3%, 92% 35%, 103% -9%, 110% 12%, 98% 107%;
    opacity: 0.9;
  }
  100% {
    background-position: -10% -10%, -25% -10%, 93% 34%, 106% -18%, 114% 8%, 100% 120%;
    opacity: 0;
  }
}